import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "../../../utils/breakpoints/"
import { colors } from "../../../utils/variables/"

// Components
import Container from "../../../components/container"
import TopBullets from "../../screens/treatment-in-minutes/top-bullets"

const StyledThankYou = styled.section`
  padding: 64px 20px;  
  min-height: 820px;

  ${Container} {
    max-width: 1100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 0%;
    padding: 0;

	  ${breakpoint.medium`
		column-gap: 10%;
	  `}
  }
  
	.landing_thankyou {
		background: ${colors.white};
		max-width: 450px;
		z-index: 10;
		flex: 1 0 auto;
		margin-bottom: 25px;
		width: 100%;
		padding: 20px 20px 0 20px;
		margin-top: 80px;
		border: 1px solid ${colors.charcoal};
		
	    ${breakpoint.small`
			max-width: 400px;
			margin-top: 0;
    	`}
    	
	    ${breakpoint.medium`
 			max-width: 450px;
    	`}

	
		h3 {
			padding: 24px 0px 16px 16px;
		}
    
    	p {
			padding: 0px 0px 16px 16px;
    		font-size: smaller;
    		line-height: 1.5em;
    	}
     
	}

	a {
	  color: ${colors.orange};

	&:hover {
	  color: ${colors.purpleDark};
	  }
	}
`

const ThankYou = props => {
	return (
		<StyledThankYou>
			<Container data-aos="fade-up">
				<TopBullets />
				<div className="landing_thankyou">
					{(props.first_name) ?
						<h3>Thanks, {props.first_name}!</h3>
						:
						<h3>Thanks!</h3>
					}
					<p>We thank you for requesting a demo of <b>Visby Medical Sexual Health Test</b>.</p>
					<p>Our team will respond to your request as soon as possible.</p>
				</div>
			</Container>
		</StyledThankYou>
	)
}

export default ThankYou
